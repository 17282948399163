'use strict'

const _ = require('lodash')
const runtimeUtil = require('../../../utils/runtime')
const constants = require('../../../utils/constants')
const {objectDiff} = require('../../../utils/objectDiff')
const {functionLibrary: componentsFunctionLibrary} = require('../componentsExtension')
const {functionLibrary: componentsRenderFunctionLibrary} = require('../../ComponentsRenderAspect/componentsRenderExtension')
const {functionLibrary: runtimeFunctionLibrary} = require('../runtimeDal/runtimeDALExtension')
const {functionLibrary: modesFunctionLibrary} = require('../../ModesAspect/modesExtension')
const {functionLibrary: actionBehaviorsFunctionLibrary} = require('../../BehaviorsAspect/actionBehaviorsExtension')
const {functionLibrary: variantsFunctionLibrary} = require('../../Variants/variantsFunctionLibrary')
const {fonts: {isUploadedFontFamily, getUploadedFontFaceStyles, getFontsMetaData}, objectUtils: {cloneDeep}} = require('santa-core-utils')

const {getMainRootIdFromContextId} = require('../../../utils/contextIdUtils')
const FONT_LAYOUT_DEBOUNCE_TIME = 32

const create = eventsManager => {
    const emitFontLoadedEvent = _.debounce(
        () => eventsManager.emit(constants.EVENTS.FONT_LOAD)
        , FONT_LAYOUT_DEBOUNCE_TIME
    )

    return _.assign({
        runIfConditionApplied: (condition, cb, params) => {
            if (condition) {
                return cb(params)
            }
            return undefined
        },
        substr: (str, start, length) => str.substr(start, length),
        isEqual: _.isEqual,
        indexOf: (str, searchValue, fromIndex) => str.indexOf(searchValue, fromIndex),
        lastIndexOf: (str, searchValue, fromIndex) => str.lastIndexOf(searchValue, fromIndex),
        split: (str, delimiter) => str.split(delimiter),
        trim: str => str.trim(),
        startsWith: _.startsWith,
        join: (arr, delimiter) => arr.join(delimiter),
        match: (str, pattern, flags) => str.match(new RegExp(pattern, flags)),
        testRegex: (pattern, flags, str) => new RegExp(pattern, flags).test(str),
        createRegex: (pattern, flags) => new RegExp(pattern, flags),
        replace: (val, match, newStr) => val.replace(match, newStr),
        ceil: Math.ceil,
        isNaN,
        isPlainObject: obj => _.isPlainObject(obj),
        toLowerCase: str => str.toLowerCase(),
        wrapWithHostHOC: _.identity,
        noop: () => {},
        promiseResolve: () => Promise.resolve(),
        identity: _.identity,
        consoleError: console.error,
        isArray: value => _.isArray(value),
        isObject: value => _.isObject(value),
        sort: strArray => strArray.slice().sort(),
        getSortedValues: obj => _(obj)
            .mapValues((val, key) => ({val, key}))
            .values()
            .sortBy('key')
            .map('val')
            .value(),
        isFinite: _.isFinite,
        isUndefined: _.isUndefined,
        get: (obj, key) => obj[key],
        objectDiff,
        removeHash: value => value && value.replace('#', ''),
        isRuntimeId: id => runtimeUtil.isRuntimeId(id),
        getRuntimeId: (compId, dataId) => runtimeUtil.getRuntimeId(compId, dataId),
        getCompIdFromRuntimeId: runtimeId => runtimeId && runtimeUtil.getCompId(runtimeId),
        getQueryIdFromRuntimeId: runtimeId => runtimeId && runtimeUtil.getQueryId(runtimeId),
        groupByCompId: arr => _.groupBy(arr, 'compId'),
        cssArrayToString: cssArr => cssArr.join('\n'),
        getComponentCssData: (getCompCssFunc, styleId, props, styleClass) => {
            try {
                return getCompCssFunc(styleId, props, styleClass)
            } catch (ex) {
            // TODO: Report error
                return null
            }
        },
        getComponentFontsData: (getCompFontsFunc, styleIds, props) => {
            try {
                return getCompFontsFunc(styleIds, props)
            } catch (ex) {
            // TODO: Report error
                return []
            }
        },
        getComponentPublicState: (getCompPublicStateFunc, compState, propsInfo) => {
            try {
                return getCompPublicStateFunc(compState, propsInfo)
            } catch (ex) {
            // TODO: Report error
                return null
            }
        },
        isUploadedFontFamily,
        getFontsMetaData,
        getUploadedFontFaceStyles,
        parseJSON: val => {
            try {
                return val && JSON.parse(val)
            } catch (e) {
                return null
            }
        },
        stringifyJSON: val => JSON.stringify(val),
        parseInt: val => parseInt(val, 10),
        parseFloat: val => parseFloat(val),
        compactOverridesArray: (mergedOverrides, setRuntimeDataOverrides, dataMapName, runtimeId) => {
            setRuntimeDataOverrides(dataMapName, runtimeId, [mergedOverrides])
            return mergedOverrides
        },
        initFontLoadingApiRegistration(windowObject, isPreviewMode) {
            if (windowObject && !isPreviewMode) {
                const {fonts} = windowObject.document
                if (fonts && 'onloadingdone' in fonts) {
                    window.document.fonts.addEventListener('loadingdone', emitFontLoadedEvent, false)
                    return true
                }
            }
            return false
        },
        onFontLoad(fontFamily) {
            //the loadedFont map is only used by fontRuller
            this.markFontAsLoaded(fontFamily, true)
            emitFontLoadedEvent()
        },
        generateActionBehaviorsEvents: ([statics, runtime]) =>
            _([statics, runtime])
                .flatMap(behaviorsForComponentAction => _.flatMap(behaviorsForComponentAction, (compActions, compId) =>
                    _.flatMap(compActions, (behaviors, eventType) =>
                        _.map(behaviors, behavior =>
                            ({compId, eventType, ...behavior.params})
                        )
                    )
                ))
                .groupBy('compId')
                .value(),
        invoke: (fn, ...params) => fn && fn(...params),
        invokeOnInstance: (instance, methodName, ...params) => instance && instance[methodName] && instance[methodName](...params),
        resolve: (check, callback, ...args) => {
            if (args.length !== 0) {
                throw new Error('do not pass arguments directly to resolve, bind with extra params')
            }
            if (check) {
                return callback()
            }

            return null
        },
        partial: (fn, ...params) => {
            const fixedParams = _.map(params, param => param === null ? _ : param)
            return _.partial(fn, ...fixedParams)
        },
        getIdOfFixedPositionWrapper: _.identity,
        propertyOf: (obj, key) => obj[key],
        includes: (str1, str2) => str1.includes(str2),
        getDateISOString: () => new Date().toISOString(),
        cloneDeep: obj => cloneDeep(obj),
        invokeObjectMethod: (obj, method, ...args) => obj && (typeof obj[method] === 'function' || null) && obj[method](...args),
        getMainRootIdFromContextId: contextId => getMainRootIdFromContextId(contextId),
        throwException: e => {throw e},
        emitEvent: (eventName, event) => eventsManager.emit(eventName, event),
        onEvent: (eventName, handler) => eventsManager.on(eventName, handler),
        onEventOnce: (eventName, handler) => eventsManager.once(eventName, handler)
    }, componentsFunctionLibrary, componentsRenderFunctionLibrary, runtimeFunctionLibrary, modesFunctionLibrary, actionBehaviorsFunctionLibrary, variantsFunctionLibrary)
}


module.exports = {
    create
}
